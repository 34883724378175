<template>
  <NuxtLayout name="minimal">
    <div class="container mx-auto pb-16 lg:pb-24 pt-6">
      <Back class="px-5 lg:px-0" to="/cart">
        <span class="text-primary-700 text-sm uppercase">
          {{ $t('backToCart') }}
        </span>
      </Back>

      <MainTitle el="h1">
        <template #before>
          {{ $t('checkout') }}
        </template>
      </MainTitle>

      <div v-if="isPageLoading" class="h-full w-full">
        <Spinner class="w-12 h-12 mx-auto" />
      </div>

      <NuxtPage v-else />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
const { isFetchingCart } = useCartAttributes();
const { isPageLoading } = useSsrCoreApp([isFetchingCart]);
</script>

<style lang="postcss">
.Checkout__container {
  display: grid;
  grid-template-columns: 1fr;
}

@screen lg {
  .Checkout__container {
    grid-template-columns: 1fr 384px;
    column-gap: 182px;
  }
}
</style>

<i18n>
{
  "en": {
    "checkout": "Checkout",
    "fewStepsToGo": "Few Steps To Go",
    "backToCart": "Back To Cart"
  },
  "ar": {
    "checkout": "الدفع",
    "fewStepsToGo": "بضع خطوات على الذهاب",
    "backToCart": "العوده للسله"
  }
}
</i18n>
